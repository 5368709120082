export default {
    data: () => ({password: ''}),
    methods: {
        setPassword (data = {}) {
            if (_.has(data, 'password')) {
                this.password = data.password
            }
        }
    },
    computed: {
        passwordOptions: () => ({}),
        passwordRules: () => []
    }
}
